import React, { Component } from 'react';
import { Modal, ModalBody, Container, Row, Col, Label } from 'reactstrap';
import { isSafari, browserVersion, isMobile, isDesktop } from 'react-device-detect';

import GradientButton from '../Components/Buttons/GradientButton';
import GradientIconButton from '../Components/Buttons/GradientIconButton';

import EADAIcon from '../assets/images/dashboard/EADA-icon.png';
import NEWBLADEIcon from '../assets/images/dashboard/NewBlade-Icon.png';
import PATIENTAPPIcon from '../assets/images/dashboard/PatientApp-Icon.png';
import ALGORITHMIcon from '../assets/images/dashboard/Algorithm-Icon.png';
import MRIIcon from '../assets/images/dashboard/MRI-Icon.png';
import ABBOTTIcon from '../assets/images/dashboard/abbott-icon.png';
import ResourcesIconWhite from '../assets/App_Icons/01-Dashboard/Resource Icon White.png';

import PWAPrompt from 'react-ios-pwa-prompt';

const SHARED_DATA_ENDPOINT = '/token';

class Dashboard extends Component {
	state = {
		displayInstallPopup: false,
		deviceOS: '',
		showPopUpInstallOnce: false
	};

	installPrompt = null;

	async componentDidMount() {
		if (isMobile) {
			window.addEventListener('beforeinstallprompt', (e) => {
				// For older browsers
				e.preventDefault();
				console.log('Install Prompt fired');
				this.installPrompt = e;
				if (!this.state.displayInstallPopup) this.setState({ deviceOS: 'android' });
			});
			if (!window.matchMedia('(display-mode: standalone)').matches) {
				if (!(isSafari && browserVersion < 13) && isMobile) {
					//this.setState({ displayInstallPopup: true });
					this.install();
				}
			}

			if (this.props.showPopUpInstallOnce) {
				this.setState({ showPopUpInstallOnce: true });
			}
		}
	}
	install = async () => {
		if (isSafari) {
			this.setState({ deviceOS: 'apple', displayInstallPopup: true });
		} else if (this.installPrompt) {
			this.installPrompt.prompt();
			let outcome = await this.installPrompt.userChoice;
			if (outcome.outcome === 'accepted') {
				this.setState({ deviceOS: '', displayInstallPopup: true });
			}
		}
	};

	render() {
		const { deviceOS, showPopUpInstallOnce } = this.state;
		return (
			<div className="page">
				<Container fluid="xl" className="noBreadcrumb ">
					<Row className="blue banner banner-image">
						<Col xs={12}>
							<div style={{ marginTop: '30px', marginBottom: '30px' }}>
								<h2
									style={{
										fontFamily: 'BrandonGrotesqueLight',
										margin: 0,
										color: '#ffffff',
										fontSize: '3vh'
									}}
								>
									THE CHOICE<br />IS IN YOUR CONTROL
								</h2>
								<h2 style={{ color: 'white', fontSize: '3vh' }}>
									WITH JOT Dx<sup>TM</sup> ICM
								</h2>
								<p style={{ color: '#fff' }}>
									Introducing the Jot Dx<sup>TM</sup> ICM System that reduces time-consuming data
									burden: a key episode* feature delivers flexibility to control the data flow. Jot Dx<sup>TM</sup>{' '}
									ICM gives your customers precise, actionable EGM insights they need to accurately
									diagnose difficult-to-detect arrhythmias.
								</p>
							</div>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col xs={12} style={{ display: 'grid' }}>
							<p
								style={{
									fontFamily: 'BrandonGrotesqueBold',
									fontSize: '2vh',
									margin: 0,
									letterSpacing: 1
								}}
							>
								KEY BENEFITS
							</p>
							<GradientButton
								text={[ 'KEY EPISODES' ]}
								gradientClass="dark-blue"
								linkPath="https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/jot-dx/why.html#KeyEpisodes"
								iconImg={EADAIcon}
							/>
							<GradientButton
								text={[ 'NEW BLADE' ]}
								gradientClass="light-blue"
								linkPath="https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/jot-dx/how-it-works.html#NewBlade"
								iconImg={NEWBLADEIcon}
							/>
							<GradientButton
								text={[ 'NEW IMPROVED PATIENT APP' ]}
								gradientClass="gold-yellow"
								linkPath="https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/jot-dx/how-it-works.html#PatientApp"
								iconImg={PATIENTAPPIcon}
							/>

							<GradientButton
								text={[ 'ALGORITHM IMPROVEMENTS' ]}
								gradientClass="dark-blue"
								linkPath="https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/jot-dx/why.html#Algorithm"
								iconImg={ALGORITHMIcon}
							/>
							<GradientButton
								text={[ 'ENTIRELY ABBOTT' ]}
								gradientClass="light-blue"
								linkPath="https://www.cardiovascular.abbott/us/en/hcp/products/cardiac-rhythm-management/insertable-cardiac-monitors/jot-dx/why.html#Abbott"
								iconImg={ABBOTTIcon}
							/>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col xs={12} style={{ display: 'grid' }}>
							<p
								style={{
									fontFamily: 'BrandonGrotesqueBold',
									fontSize: '2vh',
									margin: 0,
									letterSpacing: 1
								}}
							>
								RESOURCE LIBRARIES
							</p>
							<GradientIconButton
								text="HCP RESOURCES"
								gradientClass="dark-blue"
								linkPath="/resource-libraries/hcp-resources"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								text="PATIENT RESOURCES"
								gradientClass="light-blue"
								linkPath="/resource-libraries/patient-resources"
								iconImg={ResourcesIconWhite}
							/>
							<GradientIconButton
								text="SALES ENABLEMENT RESOURCES"
								gradientClass="gold-yellow"
								linkPath="/resource-libraries/sales-resources"
								iconImg={ResourcesIconWhite}
							/>
						</Col>
					</Row>
					<br />
					<br />
					<Row>
						<Col xs={12} style={{ display: 'grid' }}>
							<p
								style={{
									fontFamily: 'BrandonGrotesqueBold',
									fontSize: '2vh',
									margin: 0,
									letterSpacing: 1
								}}
							>
								IMPORTANT INFORMATION
							</p>
							<GradientIconButton
								text="INDICATIONS, SAFETY AND WARNINGS"
								gradientClass="dark-gray-bg"
								linkPath="resource-libraries/indications-safety-warnings"
								iconClass="las la-exclamation-circle"
							/>
						</Col>
					</Row>
					<br />
					<br />
					<Label style={{ paddingLeft: '5px', color: '#63666a' }}>v1.01</Label>
					<br />
					<br />
					<br />
					<p style={{ fontSize: '1.5vh' }}>*Key Episodes is a feature of Merlin.net</p>
					<p style={{ fontSize: '1.5vh' }}>
						© 2022 Abbott. All Rights Reserved.<br />
						MAT-2205921 v1.0 | Item is approved for US Internal use only
					</p>
				</Container>

				{deviceOS === 'apple' &&
				showPopUpInstallOnce === false && (
					<div className="installInstructions">
						<PWAPrompt
							timesToShow={10}
							delay={10}
							debug={true}
							copyTitle="Install App"
							permanentlyHideOnDismiss={true}
							onClose={() => {
								this.props.updateIfPopUpAlreadyShown();
							}}
							copyBody="This website offers app capabilities. Please add it to your home screen to improve performance and enable offline functionalities."
							copyClosePrompt={[ <span style={{ fontFamily: 'BrandonGrotesqueBold' }}>DONE</span> ]}
						/>
					</div>
				)}
				{deviceOS === 'android' &&
				showPopUpInstallOnce === false && (
					<div className="installInstructions">
						<Modal
							isOpen={deviceOS === 'android'}
							toggle={() => {
								this.setState({ deviceOS: '' }, () => {
									this.props.updateIfPopUpAlreadyShown();
								});
							}}
							style={{ maxWidth: 400, padding: 15, margin: 'auto' }}
						>
							<ModalBody>
								<p style={{ textAlign: 'center' }}>
									<span style={{ fontFamily: 'BrandonGrotesqueBold' }}>INSTALL APP</span>
									<br />
									This website offers app capabilities. Please add it to your home screen to improve
									performance and enable offline functionalities.
								</p>
								<GradientIconButton
									text="ADD TO HOMESCREEN"
									gradientClass="dark-gray-bg"
									onClick={this.install}
									iconClass="las la-download"
								/>
							</ModalBody>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}

export default Dashboard;
