import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';

import launchReadinessChecklist from '../../assets/pdf-thumbnails/52961 ICM Symptom Question Checklist_preview.png';
import launchPlaybook from '../../assets/pdf-thumbnails/53318-Internal-Jot-Dx-Playbook preview.png';
import workflowMap from '../../assets/pdf-thumbnails/012-ICM-Workflow-Mapping preview.png';

class SalesResources extends Component {
	state = {
		searchText: '',
		sortingAsc: true,
		resources: [
			{
				title: [ 'LAUNCH READINESS CHECKLIST' ],
				thumbnail: launchReadinessChecklist,
				fileName: '52896-Jot-Dx-Internal-Launch-Checklist.pdf',
				type: 'pdf'
			},
			{
				title: [ 'LAUNCH PLAYBOOK' ],
				thumbnail: launchPlaybook,
				fileName: '53666-Jot-Dx-Internal-Product-Launch-Playbook.pdf',
				type: 'pdf'
			},
			{
				title: [ 'WORKFLOW MAP' ],
				thumbnail: workflowMap,
				fileName: '012-ICM-Workflow-Mapping.pdf',
				type: 'pdf',
				offsetLimit: 5
			}
		],
		displayAsList: true
	};

	searchResources = (e) => {
		const searchText = e.target.value;
		this.setState({ searchText: searchText });
	};

	sortResources = () => {
		const sortingAsc = !this.state.sortingAsc;
		const resources = this.state.resources.sort(
			(a, b) =>
				sortingAsc
					? a.title < b.title ? -1 : a.title > b.title ? 1 : 0
					: b.title < a.title ? -1 : b.title > a.title ? 1 : 0
		);
		this.setState({ sortingAsc: sortingAsc, resources: resources });
	};

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		const { resources, searchText, sortingAsc, displayAsList } = this.state;
		const filteredResources = resources.filter((rsrc) =>
			rsrc.title.toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
		);
		console.log(resources);
		return (
			<div className="page">
				<div className="sectionBreadcrumb">
					<Link to="/">
						<i className="las la-angle-left" /> BACK
					</Link>
				</div>
				<Container fluid="xl" className="withBreadcrumb">
					<Row className="gold-yellow banner">
						<Col xs={6}>
							<div>
								<h4 style={{ color: 'white', letterSpacing: 2 }}>
									SALES ENABLEMENT<br />RESOURCES
								</h4>
							</div>
						</Col>
						{/* <Col xs={6}>
							<img src={}/>
						</Col> */}
					</Row>
					<br />
					<Row style={{ boxShadow: '#ececec 0 5px 5px' }}>
						<Col xs={12}>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText style={{ background: 'white', borderRight: 0 }}>
										<i className="las la-search" style={{ color: '#009CDE' }} />
									</InputGroupText>
								</InputGroupAddon>
								<Input
									placeholder="Search Patient Resources..."
									value={searchText}
									onChange={this.searchResources}
									style={{ borderLeft: 0, paddingLeft: 0, WebkitTapHighlightColor: 0 }}
								/>
							</InputGroup>
							<div style={{ display: 'flex', alignItems: 'center', marginTop: 15, marginBottom: 15 }}>
								<div style={{ fontSize: '1.5vh' }}>
									SORT BY:{' '}
									<span onClick={this.sortResources} style={{ color: '#009CDE' }}>
										{sortingAsc ? 'A - Z' : 'Z - A'}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginLeft: 'auto',
										color: '#BBBBBB'
									}}
								>
									<div
										style={{
											border: '1px solid #BBBBBB',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
											borderRadius: '50%',
											padding: 5,
											marginRight: 15,
											background: displayAsList ? '#009CDE' : 'white',
											color: displayAsList ? 'white' : ''
										}}
										onClick={() => this.setState({ displayAsList: true })}
									>
										<i className="las la-th-list" />
									</div>

									<div
										style={{
											border: '1px solid #BBBBBB',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
											borderRadius: '50%',
											padding: 5,
											background: !displayAsList ? '#009CDE' : 'white',
											color: !displayAsList ? 'white' : ''
										}}
										onClick={() => this.setState({ displayAsList: false })}
									>
										<i className="las la-th-large" />
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<br />
					<Row>
						<Col xs={12} />
					</Row>
					<Row>
						{displayAsList ? (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFListItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkListItem resource={resource} />
									)
							)
						) : (
							filteredResources.map(
								(resource) =>
									resource.type === 'pdf' ? (
										<PDFTileItem resource={resource} />
									) : (
										resource.type === 'link' && <LinkTileItem resource={resource} />
									)
							)
						)}
						{filteredResources.length <= 0 && (
							<Col xs={12}>
								<p style={{ textAlign: 'center' }}>No results for "{searchText}"</p>
							</Col>
						)}
					</Row>
					<br />
					<br />
					<p style={{ fontSize: '1.5vh' }}>
						<br />&copy; 2022 Abbott. All Rights Reserved.
						<br />MAT-2205927 v1.0 | Item is approved for US Internal use only
					</p>
				</Container>
			</div>
		);
	}
}

class PDFListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<Link
					to={`/resource-libraries/pdf-viewer?title=${resource.title
						.map((t) => (t instanceof Object ? 'TM' : t))
						.join('')}&file=${resource.fileName}${resource.offsetLimit
						? '&ol=' + resource.offsetLimit
						: ''}`}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							borderBottom: '1px solid #BBBBBB',
							paddingTop: 10,
							paddingBottom: 10
						}}
					>
						<img
							src={resource.thumbnail}
							style={{
								height: '5vh',
								marginRight: 10
							}}
						/>
						<div
							style={{
								fontSize: '1.5vh',
								lineHeight: '1.5vh',
								color: '#63666A',
								fontFamily: 'BrandonGrotesqueBold'
							}}
						>
							{resource.title}
						</div>

						<i className="las la-angle-right" style={{ marginLeft: 'auto', color: 'gray' }} />
					</div>
				</Link>
			</Col>
		);
	}
}

class LinkListItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={12}>
				<a href={`${resource.url}`} target="_blank" rel="noopener noreferrer">
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: '100%',
							borderBottom: '1px solid #BBBBBB',
							paddingTop: 10,
							paddingBottom: 10
						}}
					>
						<img
							src={resource.thumbnail}
							style={{
								height: '5vh',
								marginRight: 10
							}}
						/>
						<div
							style={{
								fontSize: '1.5vh',
								lineHeight: '1.5vh',
								color: '#63666A',
								fontFamily: 'BrandonGrotesqueBold'
							}}
						>
							{resource.title}
						</div>

						<i className="las la-angle-right" style={{ marginLeft: 'auto', color: 'gray' }} />
					</div>
				</a>
			</Col>
		);
	}
}

class PDFTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<Link to={`/resource-libraries/pdf-viewer?title=${resource.title}&file=${resource.fileName}`}>
					<img
						src={resource.thumbnail}
						style={{ maxHeight: '20vh', border: '1px solid #dcdcdb', maxWidth: '100%' }}
					/>
					<div
						style={{
							fontSize: '1.2vh',
							lineHeight: '1.5vh',
							marginTop: 5,
							marginBottom: 15,
							color: '#63666A',
							fontFamily: 'BrandonGrotesqueBold'
						}}
					>
						{resource.title}
					</div>
				</Link>
			</Col>
		);
	}
}

class LinkTileItem extends Component {
	render() {
		const { resource } = this.props;
		return (
			<Col xs={6} lg={3}>
				<a href={`${resource.url}${resource.offsetLimit ? '&ol=' + resource.offsetLimit : ''}`}>
					<img
						src={resource.thumbnail}
						style={{ maxHeight: '20vh', border: '1px solid #dcdcdb', maxWidth: '100%' }}
					/>
					<div
						style={{
							fontSize: '1.2vh',
							lineHeight: '1.5vh',
							marginTop: 5,
							marginBottom: 15,
							color: '#63666A',
							fontFamily: 'BrandonGrotesqueBold'
						}}
					>
						{resource.title}
					</div>
				</a>
			</Col>
		);
	}
}

export default SalesResources;
